<template>
  <div>

    <b-row class="lign-self-stretch">
      <b-col xl="4" lg="6" sm="12" v-if="!isEmpty(card.value)" v-for="card in tourCards" :key="card.label" class="mb-2">
        <b-card class="shadow-sm h-100">
          <b-card-title>
            {{card.label}}
            <fa class="fa-pull-right" :icon="card.icon"></fa>
          </b-card-title>

          <hr/>

          <template v-if="card.label === 'Staff'">
            <div class="property-card-body">
              <div v-for="(staff, index) in card.value">
                <b>
                  {{$const.STAFF_TYPES[staff.type]}} - {{$const.STAFF_ACTIVITY_LEVEL[staff.activity_level]}}
                  <span v-if="staff.staff">: {{staff.staff.contact.first_name}} {{staff.staff.contact.last_name}}</span>
                  <b-badge class="ml-2" variant="success" v-if="tourStaff && staff.share_id == tourStaff.share_id">me</b-badge>
                </b>
                <DecoratedProperty :type="prop.type" :variant="prop.variant" :icon="prop.icon" :value="prop.get(staff)" v-for="(prop, index) in staffProps" :key="index"></DecoratedProperty>
                <ContactProperty v-if="staff.staff" :contact="staff.staff.contact"></ContactProperty>
                <hr v-if="index < card.value.length - 1"/>
              </div>
              <ContactProperty :contact="card.value"></ContactProperty>
            </div>
          </template>

          <template v-if="card.label === 'Timing'">
            <!--<wrapped-text :text="card.value" line-class="mb-2"></wrapped-text>-->
            <div>
              <div v-for="(item, index) of card.value" :key="index" class="d-flex border-bottom pb-1 mb-1">
                <div v-if="item.start" class="mr-2">
                  <b-badge>
                    <span>{{item.start}}</span>
                    <span v-if="item.end"> - {{item.end}}</span>
                  </b-badge>
                </div>
                {{item.text}}
              </div>
            </div>
          </template>

          <template v-else-if="card.label === 'Route'">
            <tour-route :tour="tour" :tourStaff="tourStaff" />
          </template>

          <template v-if="card.label === 'Transport'">
            <div class="property-card-body">
              <div v-for="(transport, index) in card.value">
                <b>{{transport.type.name}}</b>
                <DecoratedProperty :type="prop.type" :variant="prop.variant" :icon="prop.icon" :value="prop.get(transport)" v-for="(prop, index) in transportProps" :key="index"></DecoratedProperty>
                <ContactProperty :contact="transport.driver"></ContactProperty>
                <hr v-if="index < card.value.length - 1"/>
              </div>
            </div>
          </template>

          <template v-else-if="card.label === 'Inside'">
            <div class="property-card-body" v-if="tourInside.inside" v-for="(tourInside, index) in card.value" :key="index">
              <div><b>{{tourInside.inside.name}}</b></div>
              <DecoratedProperty :type="prop.type" :variant="prop.variant" :icon="prop.icon" :value="prop.get(tourInside)" v-for="(prop, index) in insideProps" :key="index"></DecoratedProperty>
              <ContactProperty v-if="!hideSupplierDetails" :contact="tourInside.inside.contact"></ContactProperty>
              <hr v-if="index < card.value.length - 1"/>
            </div>
          </template>

          <template v-else-if="card.label === 'F&B'">
            <div class="property-card-body" v-if="tourFnb.fnb" v-for="(tourFnb, index) in card.value" :key="index">
              <div><b>{{tourFnb.fnb.name}}</b></div>
              <DecoratedProperty :type="prop.type" :variant="prop.variant" :icon="prop.icon" :value="prop.get(tourFnb)" v-for="(prop, index) in fnbProps" :key="index"></DecoratedProperty>
              <ContactProperty v-if="!hideSupplierDetails" :contact="tourFnb.fnb.contact"></ContactProperty>
              <hr v-if="index < card.value.length - 1"/>
            </div>
            <b-alert v-else class="text-uppercase" variant="warning" show>No F&B selected</b-alert>
          </template>

          <template v-else-if="card.label === 'Description'">
            <wrapped-text :text="card.value"></wrapped-text>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
  import {Component, Vue, Prop} from 'vue-property-decorator'
  import {Tour, TourFnb, TourStaff, TourInside, TourTransport, addTimingOffset} from '@eguide/api';
  import DecoratedProperty from './DecoratedProperty.vue'
  import ContactProperty from './ContactProperty.vue'
  import TourRoute from '../../misc/components/TourRoute.vue'
  import * as _ from 'lodash'
  import WrappedText from "@hc/ui/util/components/WrappedText.vue";
  import {formatTime} from "@hc/graphql";

  @Component({components:{WrappedText, DecoratedProperty, ContactProperty, TourRoute}})
  export default class TourDescription extends Vue{
    @Prop({type: Object, required: true}) tour!: Tour
    @Prop({type: Object}) tourStaff!: TourStaff

    isEmpty(val) {
      return _.isEmpty(val)
    }

    getTourComponents(type: string){
       return _.get(this.tour,type)
    }

    get hideSupplierDetails() {
      return this.tour.ship_call.onsite_office.hide_supplier_details
    }

    get tourCards(){
      return [
        {value: this.getTourComponents('transports'), label: 'Transport', icon: 'bus'},
        {value: this.getTourComponents('insides'), label: 'Inside', icon:'landmark'},
        {value: this.getTourComponents('fnbs'), label: 'F&B', icon:'fish'},
        {value: 'value', label: 'Route', icon:'route'},
        {value: addTimingOffset(this.tour.start_time, this.tour.timing), label: 'Timing', icon:'clock'},
        {value: _.get(this.tour,'description'), label: 'Description', icon:'book'},
        {value: this.getTourComponents('staff'), label: 'Staff', icon: 'user'},

      ]
    }

    get transportProps(){
      return [
        {get:(v: TourTransport) => v.additional_info, icon:'exclamation-triangle', variant:'warning'},
        {get:(v: TourTransport) => String(v.vehicle?.capacity || ""), icon:'chair'},
        {get:(v: TourTransport) => v.vehicle?.plate, icon:'sign'},
        {get:(v: TourFnb) => formatTime(v.meeting_time), icon:'clock'},
        {get:(v: TourTransport) => formatTime(v.start_time), icon:'plane-departure'},
        {get:(v: TourTransport) => v.start_location ? v.start_location.name + "\n" + v.start_location.address : null, icon:'map-marker', type: 'address'},
        {get:(v: TourTransport) => formatTime(v.end_time), icon:'plane-arrival'},
        {get:(v: TourTransport) => v.end_location ? v.end_location.name + "\n" + v.end_location.address : null, icon:'map-marker', type: 'address'}
      ]
    }

    get fnbProps(){
      return [
        {get:(v: TourFnb) => v.additional_info, icon:'exclamation-triangle', variant:'warning'},
        {get:(v: TourFnb) => v.fnb?.menu, icon:'fish'},
        {get:(v: TourFnb) => v.fnb?.drink, icon:'wine-glass'},
        {get:(v: TourFnb) => formatTime(v.meeting_time), icon:'clock'},
        {get:(v: TourFnb) => formatTime(v.start_time), icon:'plane-departure'},
        {get:(v: TourFnb) => v.fnb ? v.fnb.location.name + "\n" + v.fnb.location.address : null, icon:'map-marker', type: 'address'},
        {get:(v: TourFnb) => formatTime(v.end_time), icon:'plane-arrival'}
      ]
    }

    get insideProps(){
      return [
        {get:(v: TourInside) => v.additional_info, icon:'exclamation-triangle', variant:'warning'},
        {get:(v: TourInside) => v.inside?.comment, icon:'info'},
        {get:(v: TourInside) => formatTime(v.meeting_time), icon:'clock'},
        {get:(v: TourInside) => formatTime(v.start_time), icon:'plane-departure'},
        {get:(v: TourInside) => v.inside ? v.inside.location.name + "\n" + v.inside.location.address : null, icon:'map-marker', type: 'address'},
        {get:(v: TourInside) => formatTime(v.end_time), icon:'plane-arrival'}
      ]
    }

    get staffProps(){
      return [
        {get:(v: TourStaff) => v.additional_info, icon:'exclamation-triangle', variant:'warning'},
        {get:(v: TourStaff) => formatTime(v.meeting_time), icon:'clock'},
        {get:(v: TourStaff) => formatTime(v.start_time), icon:'plane-departure'},
        {get:(v: TourStaff) => v.start_location ? v.start_location.name + "\n" + v.start_location.address : null, icon:'map-marker', type: 'address'},
        {get:(v: TourStaff) => formatTime(v.end_time), icon:'plane-arrival'},
        {get:(v: TourStaff) => v.end_location ? v.end_location.name + "\n" + v.end_location.address : null, icon:'map-marker', type: 'address'},
      ]
    }
  }
</script>

<style lang="sass">
  @media (min-width: 576px)
    .card-columns
      -webkit-column-count: 2 !important
      column-count: 2 !important

    @media (min-width: 1024px)
      .card-columns
        -webkit-column-count: 3 !important
        column-count: 3 !important

  .property-card-body
    padding-left: 30px !important

  .icon-badge
    margin-left: -30px !important
    margin-right: 3px
    svg
      width: 12px !important
</style>
